.MFACodeInput {
  .customCaret {
    position: relative;
    caret-color: transparent;
  }

  .description {
    padding: 10px;
    text-align: center;
  }

  .error {
    padding: 10px;
    text-align: center;
    color: #ff4d4f;
  }
  input:hover{
    border-color:#696969;
  }
  input:hover:focus{
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
  }
}